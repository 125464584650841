* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --primaryColor: #fe6c40;
    --linkColor: rgb(68, 132, 255);
    --transparentPrColor: rgba(42, 184, 115, 0.50);
    --warning: rgba(248, 99, 99, 0.423);
    --background-color: #fff;
    --headerBg: #fff;
    --text-color: #000;
    --secondaryTextColor: #555555;
    --tertiaryTextColor: #a1a1a1;
    --borderColor: #3331;
    --productBoxBg: rgb(245, 245, 245);
    --slightTransparentBg: rgba(69, 69, 69, 0.067);
    --cartCheckoutLoadingBtnBg: rgba(243, 243, 243, 1);
    --headerShadowColor: #33333325;
    --searchResBg: #282727;
    --ratingUnactiveStarFill: #3331;
    --navShadow: -15px 0 43px #3333339e;
    --notifcationShadow: #333333d1;
    --categoryIconsInvert: invert(0) grayscale(0%);
    --mobileNavBorder: rgba(0, 0, 0, 0.15);
    --barBorderClr: rgba(255, 255, 255, 0.9);
    --toggleBtnBg: rgba(128, 128, 128, 0.167);
    --loaderBg: white;
    --imageCropPopupBg: rgba(0, 0, 0, 0.482);
    --imageColorInvert: invert(0%);
    --loadingBoxElmBg: linear-gradient(100deg, #f0f0f0 40%, #e0e0e0 50%, #f0f0f0 60%);
    --bgLoadingAnimLinearGradient: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
}

[theme='dark']:root {
    --background-color: #181818;
    --linkColor: rgb(126, 170, 255);
    --headerBg: color(srgb 0.2431 0.2431 0.2432);
    --text-color: #fff;
    --secondaryTextColor: #b3b3b3;
    --tertiaryTextColor: #696969;
    --borderColor: #a8a8a823;
    --productBoxBg: rgb(37, 37, 37);
    --slightTransparentBg: #c6c6c613;
    --cartCheckoutLoadingBtnBg: rgba(42, 42, 42, 1);
    --headerShadowColor: #0000003d;
    --searchResBg: #282727;
    --ratingUnactiveStarFill: #333;
    --navShadow: -15px 0 43px #00000021;
    --notifcationShadow: #000000e2;
    --categoryIconsInvert: invert(1) grayscale(100%);
    --mobileNavBorder: rgba(255, 255, 255, 0.15);
    --barBorderClr: rgba(255, 255, 255, 0.4);
    --toggleBtnBg: rgba(128, 128, 128, 0.345);
    --loaderBg: #3e3e3e;
    --imageCropPopupBg: rgba(65, 65, 65, 0.62);
    --imageColorInvert: invert(85%);
    --loadingBoxElmBg: linear-gradient(100deg, #2a2a2a 40%, #3a3a3a 50%, #2a2a2a 60%);
    --bgLoadingAnimLinearGradient: linear-gradient(to right, #222222 8%, #3a3a3a46 18%, #222222 33%);
}

body {
    min-height: 100vh;
    background: var(--background-color);
    color: var(--text-color);
    font-family: sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    position: relative;
}

.no-scroll {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    touch-action: none;
}

a {
    color: inherit;
    text-decoration: none;
}

svg {
    vertical-align: middle;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
}

.error-while-loading {
    -webkit-filter: var(--imageColorInvert);
    filter: var(--imageColorInvert);
    transition: .3s ease-in-out;
}

.sliderContainer {
    margin-top: 20px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

button,
input {
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}

button {
    cursor: pointer;
}

.container {
    width: 80%;
    margin: auto;
    max-width: 1300px;
}

.breadcrumb {
    font-weight: 500;
    font-size: 15px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.breadcrumb .breadcrumb-current {
    cursor: default;
}

.fade-transition {
    opacity: 0;
    transition: opacity .5s ease-in-out;
}

.fade-transition.active {
    opacity: 1;
}

/* ---------------- map style ---------------- */
.gps_ring {
    border: 3px solid var(--primaryColor);
    -webkit-border-radius: 30px;
    border-radius: 30px;
    height: 18px;
    width: 18px;
    -webkit-animation: pulsate 1s ease-out;
    animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 0.0
}

@-webkit-keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0.0;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0.0;
    }
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0.0;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0.0;
    }
}

/* ---------------- header menu ---------------- */
.header-menu {
    display: none;
    position: relative;
    border: none;
    -webkit-tap-highlight-color: transparent;
}

.header-menu input {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
}

.header-menu span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: var(--tertiaryTextColor);
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
}

.header-menu span:last-child {
    margin-bottom: 0;
}

.header-menu:hover span,
.header-menu input:checked~span {
    background: var(--primaryColor);
}

.header-menu span:first-child {
    transform-origin: 0% 0%;
}

.header-menu span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

.header-menu input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-10px, -18px);
}

.header-menu input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(-4px, 14px);
}

.header-menu input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/* ---------------- default classes ---------------- */
.button-disable {
    background: #848484 !important;
    border-color: #848484 !important;
    color: rgb(206, 206, 206) !important;
    pointer-events: none;
    opacity: .6;
}

.dn {
    display: none;
}

.df {
    display: flex;
}

.dg {
    display: grid;
}

.fdc {
    flex-direction: column;
}

.fdr {
    flex-direction: row;
}

.gap5 {
    gap: 5px;
}

.gap10 {
    gap: 10px;
}

.gap20 {
    gap: 20px;
}

.alic {
    align-items: center;
}

.alis {
    align-items: flex-start;
}

.alie {
    align-items: flex-end;
}

.jstfy-btwn {
    justify-content: space-between;
}

.jstfy-s {
    justify-content: flex-start;
}

.jstfy-e {
    justify-content: flex-end;
}

.jstfy-c {
    justify-content: center;
}

.c-pointer {
    cursor: pointer;
}

.bt1 {
    border-top: 1px solid var(--borderColor);
}

.bb1 {
    border-bottom: 1px solid var(--borderColor);
}

.op07 {
    opacity: .7;
}

.pointr_evnt_n {
    pointer-events: none;
}

.sml_b {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .2px;
}

.hvrAnmDur {
    transition: .2s ease-in-out;
}

.hvrClrEfctPr:hover {
    color: var(--primaryColor);
}

.hvrBgEfctPr:hover {
    background: var(--primaryColor);
}

.two-lines {
    display: -webkit-box;
    line-height: 1.3;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.u_sel_none {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
}

.pr {
    position: relative;
}

.pa {
    position: absolute;
}

.pf {
    position: fixed;
}

.p-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.t-left {
    text-align: left;
}

.t-center {
    text-align: center;
}

.text-capitalize {
    text-transform: capitalize;
}

.t-right {
    text-align: right;
}

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mta {
    margin-top: auto;
}

.mba {
    margin-bottom: auto;
}

.mla {
    margin-left: auto;
}

.mra {
    margin-right: auto;
}

.m0 {
    margin: 0 !important;
}

.p0 {
    padding: 0 !important;
}

.pt5 {
    padding-top: 5px;
}

.pt10 {
    padding-top: 10px;
}

.pb0 {
    padding-bottom: 0;
}

.pb5 {
    padding-bottom: 5px;
}

.pb10 {
    padding-bottom: 10px;
}

.pr-color {
    color: var(--primaryColor) !important;
}

.secondary-color {
    color: var(--secondaryTextColor)
}

.tertiary-color {
    color: var(--tertiaryTextColor);
}

.link-clr {
    color: var(--linkColor);
}

.oh {
    overflow: hidden;
}

/* ---------------- width ---------------- */
.w100 {
    width: 100%;
}

.h100 {
    height: 100%;
}

/* ---------------- margins ---------------- */

.mb5 {
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

/* ---------------- notification ---------------- */
.notification-main {
    position: fixed;
    top: 80px;
    right: -100%;
    z-index: 1;
    width: 300px;
    max-width: 90%;
    border-radius: 10px;
    background: var(--productBoxBg);
    box-shadow: 8px 15px 71px var(--notifcationShadow);
    transition: .3s ease-in-out;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 5px;
}

.notification-main.active {
    right: 10%;
}

.notification-main .notif-icon-container {
    padding: 10px 0 10px 8px;
    display: flex;
    align-items: center;
}

.notification-main .notif-content-wrapper {
    padding: 8px 0 10px 0;
}

.notification-main .title {
    color: var(--secondaryTextColor);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    text-transform: capitalize;
}

.notification-main .message {
    font-size: 13px;
    color: var(--tertiaryTextColor);
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.notification-main .notif-action-btns {
    display: flex;
    border-left: 2px solid var(--borderColor);
}

.notification-main .notif-action-btns.dn {
    display: none !important;
}

.notification-main .notif-action-btns button {
    border: none;
    background: none;
    font-size: 15px;
    height: 100%;
    padding: 0 8px;
    text-transform: capitalize;
    color: var(--secondaryTextColor);
    -webkit-tap-highlight-color: none;
}

.notification-main .notif-action-btns button:nth-child(2) {
    border-top: 2px solid var(--borderColor);
}

/* ---------------- toggle btn ---------------- */
.toggle-btn-outer {
    width: 50px;
    height: 28px;
    background: var(--toggleBtnBg);
    border-radius: 100px;
    position: relative;
    transition: 0.2s cubic-bezier(0, 0, 0, 0.1);
}

.toggle-btn-outer.active {
    background: var(--primaryColor);
}

.toggle-btn-outer .toggle-btn-inner {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    top: 2px;
    cursor: pointer;
    background: white;
    transition: 0.35s cubic-bezier(0.22, 0.96, 0.56, 1.04);
    -webkit-tap-highlight-color: transparent;
}

.toggle-btn-outer.active .toggle-btn-inner {
    left: 24px;
    background: white;
}

/* ---------------- button with loading animation ---------------- */

.button-with-loading-anim {
    position: relative;
    overflow: hidden;
    width: 7rem;
    color: white;
    border: 2px solid var(--primaryColor);
    cursor: pointer;
    line-height: 2;
    padding: 0;
    border-radius: 1.5rem;
    font-size: 1.125rem;
    text-transform: capitalize;
    outline: none;
    transition: transform 0.125s;
}

.button-with-loading-anim:active {
    transform: scale(0.9, 0.9);
}

.button-with-loading-anim:before,
.button-with-loading-anim:after {
    position: absolute;
    opacity: 0;
    border-radius: 50%;
    background-color: #fff;
    top: 50%;
    left: 50%;
    margin-top: -18px;
    margin-left: -18px;
    width: 36px;
    height: 36px;
    content: "";
    z-index: 1;
}

.button-with-loading-anim.loading {
    animation: button-loading 0.5s forwards;
}

.button-with-loading-anim.loading:before {
    opacity: 1;
    animation: button-dot-intro 0.5s forwards;
}

.button-with-loading-anim.loading:after {
    opacity: 0;
    animation: button-dot-pulse 1.5s infinite 0.5s;
}

.button-with-loading-anim.ready {
    text-indent: 0;
    color: transparent;
    background-color: var(--primaryColor);
    animation: button-ready 0.333s forwards;
}

.button-with-loading-anim.ready:before {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    margin: 0;
    width: auto;
    height: auto;
    border-radius: 0;
    background-color: transparent;
    color: #8cce1e;
    content: "ready";
    opacity: 0;
    z-index: 2;
    animation: button-ready-label 0.5s forwards 0.275s;
}

.button-with-loading-anim.ready:after {
    opacity: 1;
    animation: button-dot-outro 0.333s;
}

/* ---------------- additional sytlings ---------------- */
.product-page-container .cart-page-btn .cart-counter,
.header-mobile-navigation .mobile-cart-menu span {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--primaryColor);
    box-shadow: 3px 3px 10px #3333338b;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.product-page-container .cart-page-btn .cart-counter.active,
.header-mobile-navigation .mobile-cart-menu .active {
    opacity: 1;
    visibility: visible;
}

/* ---------------- sign in / log in button animation style start ---------------- */
@keyframes button-loading {
    20% {
        color: transparent;
        transform: scale(1, 1);
    }

    40% {
        border-color: var(--primaryColor);
        background-color: transparent;
        transform: scale(1, 1);
    }

    60% {
        transform: scale(0.7, 1.1);
        margin-left: 1.25rem;
        width: 2.5rem;
        text-indent: -0.6125rem;
        color: transparent;
        border-color: var(--primaryColor);
        background-color: var(--primaryColor);
    }

    80% {
        transform: scale(1, 1);
    }

    100% {
        margin-left: 1.25rem;
        width: 2.5rem;
        background-color: var(--primaryColor);
        border-color: var(--primaryColor);
        color: transparent;
    }
}

@keyframes button-dot-intro {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 1;
        transform: scale(1, 1);
    }

    100% {
        transform: scale(0.75, 0.75);
    }
}

@keyframes button-dot-pulse {
    0% {
        opacity: 1;
        transform: scale(0.75, 0.75);
    }

    15% {
        transform: scale(0.85, 0.85);
    }

    45% {
        transform: scale(0.75, 0.75);
    }

    55% {
        transform: scale(0.95, 0.95);
    }

    85% {
        transform: scale(0.75, 0.75);
    }

    100% {
        opacity: 1;
        transform: scale(0.75, 0.75);
    }
}

@keyframes button-ready {
    0% {
        margin-left: 1.25rem;
        width: 2.5rem;
    }

    10% {
        background-color: var(--primaryColor);
        border-color: var(--primaryColor);
    }

    70% {
        margin: 0;
        width: 7.25rem;
        background-color: #fff;
        transform: scale(1.1, 1.1);
    }

    100% {
        margin: 0;
        width: 7rem;
        border-color: #8cce1e;
        background-color: #fff;
    }
}

@keyframes button-dot-outro {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(1, 1);
    }
}

@keyframes button-ready-label {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* ---------------- sign in / log in button animation style end ---------------- */
/* ---------------- quantity ---------------- */
.quantity {
    width: 90px;
    height: max-content;
    display: flex;
    overflow: hidden;
    border-radius: 100px;
    border: 1px solid var(--borderColor);
}

.quantity input {
    min-height: 100%;
    border: none;
    width: 100%;
    background: none;
    font-size: 16px;
    text-align: center;
    appearance: none;
    -moz-appearance: none;
}

.quantity button {
    background: none;
    border: none;
    color: var(--secondaryTextColor);
    font-size: 17px;
    line-height: 17px;
    padding: 4px 10px;
}

/* ---------------- loading ---------------- */
.loader-outer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: var(--loaderBg);
    z-index: 99;
    opacity: 1;
    visibility: visible;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-outer.transparent {
    opacity: .5;
}

.loader-outer .loader {
    position: initial;
}

.loader-outer.main {
    z-index: 9999999;
}

.loader-outer.hide {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid var(--text-color);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: calc(50% + 24px);
    left: calc(50% - 24px);
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-box-elm {
    width: max-content;
    height: auto;
    position: relative;
    border-radius: inherit;
    overflow: hidden;
    color: transparent;
    transition: .3s ease-in-out;
}

.loading-box-elm:where(:not([style*="border-radius"])) {
    border-radius: 4px;
}

.loading-box-elm.showbgborder {
    background: var(--productBoxBg) !important;
    border-color: var(--productBoxBg) !important;
}

.loading-box-elm::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--loadingBoxElmBg);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
    border-radius: inherit;
    transition: inherit;
}

.loading-box-elm.hide::after {
    opacity: 0;
    visibility: hidden;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

/* ---------------- disable hover effect for touch screen device ---------------- */

@media (hover: none) {
    *:hover {
        background: '' !important;
    }
}

/* ---------------- responsive ---------------- */
@media only screen and (max-width: 1200px) {
    .container {
        width: 85%;
    }

    .notification-main.active {
        right: 7.5%;
    }
}

@media only screen and (max-width: 992px) {
    .container {
        width: 88%;
    }

    .notification-main.active {
        right: 6%
    }
}

@media only screen and (max-width: 768px) {
    .container {
        width: 95%;
    }

    .df-mobile {
        display: flex;
    }

    .header-mobile-navigation .mobile-cart-menu span {
        font-size: 11.5px;
        color: white;
    }

    .notification-main.active {
        top: 60px;
        right: 2.5%;
    }

    .header-menu {
        position: fixed;
        right: 3%;
        z-index: 1;
        height: 28px;
        display: flex;
        z-index: 9999;
        flex-direction: column;
        justify-content: space-between;
    }

    .header-menu span {
        width: 36px;
        height: 5px;
    }

    .fdc-mobile {
        flex-direction: column;
    }

    .gap5-mobile {
        gap: 5px;
    }
}

@media only screen and (max-width: 370px) {
    .fdc-mobile-small {
        flex-direction: column;
    }
}