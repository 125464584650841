.seller-product-edit{
    margin: 0 10px;
    margin-bottom: 80px;
}

.input-split small,
.desc-and-feature small {
    text-transform: initial;
}

.inp-boxes-grid-container {
    border-radius: 5px;
    background: var(--productBoxBg);
    display: flex;
    flex-wrap: wrap;
    font-weight: 600;
    font-size: 13px;
    padding: 10px 10px 20px 10px;
    color: var(--text-color);
    text-transform: capitalize;
    justify-content: space-between;
}

.inp-boxes-grid-container .inp-box {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    font-weight: lighter;
    flex-direction: column;
    gap: 3px;
}

.inp-boxes-grid-container .inp-box.modelname {
    width: 150px;
}

.inp-boxes-grid-container .inp-box.brand {
    width: 170px;
}

.inp-boxes-grid-container .inp-box span:first-child {
    color: var(--secondaryTextColor);
}

.inp-boxes-grid-container .product-edit-mode {
    margin-bottom: 10px;
}

.inp-boxes-grid-container .inp-box.desc-and-feature .dflex {
    justify-content: space-between;
}

.inp-boxes-grid-container .inp-box.desc-and-feature .options .make-list-item {
    color: #3333;
    cursor: pointer;
    font-weight: bold;
    user-select: none;
    transition: .2s ease;
}

.inp-boxes-grid-container .inp-box.desc-and-feature .options .make-list-item:hover {
    color: inherit;
}

.inp-boxes-grid-container .inp-box.desc-and-feature .options .make-list-item.active {
    color: black;
}

.inp-boxes-grid-container .inp-box.desc-and-feature .options.unactive {
    pointer-events: none;
}

.inp-boxes-grid-container .inp-box textarea {
    height: 200px;
    padding: 10px;
    border: 1px solid var(--borderColor);
    color: var(--text-color);
    background: var(--productBoxBg);
    resize: none;
    outline: none;
    border-radius: 4px;
    font-family: inherit;
    font-size: 16px;
}

.inp-boxes-grid-container .inp-box .desc-feature-inp {
    resize: vertical !important;
}

.inp-boxes-grid-container .inp-box .drag-drop-box {
    background: var(--productBoxBg);
    height: 200px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    transition: .5s cubic-bezier(0.4, 0, 1, 1);
}

.inp-boxes-grid-container .inp-box .media-display .media-img-container {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    background: rgba(134, 134, 134, 0.427);
}

.inp-boxes-grid-container .media-img-container .delete-media-img {
    top: 5px;
    right: 5px;
    cursor: pointer;
    padding: 2px 4px;
    z-index: 1;
    border-radius: 2px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    transition: .3s ease-in-out;
    color: var(--text-color) !important;
}

.inp-boxes-grid-container .media-img-container .delete-media-img:hover {
    background: #000;
}

.inp-boxes-grid-container .media-img-container::after {
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: #0000004d;
}

.inp-boxes-grid-container .inp-box .media-display img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.inp-boxes-grid-container .inp-box .drag-drop-box.hide {
    height: 0;
}

.inp-boxes-grid-container .inp-box .drag-drop-box .box-inner {
    width: 100%;
    height: 100%;
    border: 3px dashed var(--borderColor);
    transition: inherit;
}

.inp-boxes-grid-container .inp-four-grid {
    width: 100%;
    gap: 20px;
}

.inp-boxes-grid-container .input-split {
    width: 100%;
    display: flex;
    gap: inherit
}

.inp-boxes-grid-container .input-split .inp-box {
    width: 50% !important;
    max-width: 50%;
}

.inp-boxes-grid-container .input-split .inp-box input {
    width: 100%;
}

.inp-boxes-grid-container .inp-box .drag-drop-box.dragover {
    background: #00000015;
}

.inp-boxes-grid-container .inp-box .drag-drop-box.dragover .box-inner {
    border: 3px dashed #9b9b9b;
}

.inp-boxes-grid-container .inp-box.media-drag-drop input {
    cursor: pointer;
    margin-left: 5px;
}

.inp-boxes-grid-container .inp-box .drag-drop-box .mid-content {
    top: 50%;
    left: 50%;
    font-size: 24px;
    text-align: center;
    position: absolute;
    font-weight: lighter;
    transform: translate(-50%, -50%);
    width: max-content;
}

.inp-boxes-grid-container .inp-box .drag-drop-box .mid-content span:nth-child(3) {
    font-size: 20px;
    text-transform: lowercase;
    color: var(--tertiaryTextColor)
}

.inp-boxes-grid-container .inp-box select {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    height: 40px;
    border: none;
    outline: none;
    background: none;
    appearance: none;
    cursor: pointer;
    color: inherit;
    font-size: 13px;
    color: var(--secondaryColor);
}

.inp-box.balcony,
.inp-box.parking,
.inp-box.garage {
    align-items: center;
}

.inp-boxes-grid-container .inp-box input:focus {
    background: #F1F1F1;
}

.inp-boxes-grid-container .inp-box .drag-drop-box .mid-content .inn-media-inp {
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 15px;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    width: max-content;
    margin: auto;
    font-weight: 600;
    letter-spacing: .2px;
    margin-top: 4px;
    color: var(--primaryColor);
}

.inp-boxes-grid-container .inp-box .drag-drop-box .mid-content .inn-media-inp:hover {
    color: var(--tertiaryTextColor);
    text-decoration: underline;
}

.inp-boxes-grid-container .inp-box .drag-drop-box .mid-content .inn-media-inp input[type='file'] {
    width: 200px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
}

.inp-boxes-grid-container .inp-box input:not(input[type="checkbox"]),
.inp-boxes-grid-container .inp-box .inp-box-inner {
    height: 40px;
    border: none;
    outline: none;
    background: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 0 10px;
    color: var(--text-color);
    border: 1px solid var(--borderColor);
}

.inp-boxes-grid-container .inp-box .media-display {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 10px;
    margin-top: 10px;
}

.stock-inp-field {
    height: 40px;
    min-height: 41px;
    overflow: hidden;
    transition: .3s ease-in-out;
}

.stock-inp-field .inp-box {
    width: 100px;
    margin-bottom: 0;
    transition: .3s ease-in-out;
}

.stock-inp-field .inp-box input {
    text-align: center;
}

.stock-inp-field .inp-box-inner {
    width: 100%;
}

.category-inp-field {
    height: 65px;
    margin-bottom: 10px !important;
    overflow: hidden;
    transition: .3s ease-in-out;
}

.category-add-request span {
    text-transform: initial;
    display: block;
}

.inp-color-display {
    padding: 0;
    flex-wrap: wrap;
    transition: .3s ease-in-out;
}

.inp-color-display.active {
    padding: 4px 0;
}

.inp-color-display span {
    padding: 3px 5px;
    border-radius: 3px;
    min-width: max-content;
    border: 1px solid var(--borderColor);
    color: var(--secondaryTextColor) !important;
}

.image-upload-note {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 13px;
}

.show-loading-win .loader {
    position: unset;
    display: block;
}

.product-upload-progress {
    position: relative;
}

.product-upload-progress progress::-webkit-progress-value {
    background-color: var(--primaryColor);
}

.add-product-submit-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ---------------- add a custom field ---------------- */
.add-a-custom-field {
    height: 40px;
    overflow: hidden;
    transition: .3s ease-in-out;
}

.add-a-custom-field .custom-field-heading {
    min-height: 40px;
}

.add-a-custom-field .header-menu {
    display: flex;
    width: 33px;
    height: 28px;
    position: relative;
    z-index: 1;
    right: 5px
}

.add-a-custom-field .custom-hidden-field {
    display: grid;
    grid-template-columns: 100px 1fr 25px;
    gap: 10px;
    margin-bottom: 10px;
}

.add-a-custom-field .custom-hidden-field .inp-box{
    margin-bottom: 0;
}

.add-a-custom-field .custom-hidden-field .custom-field .inp-box {
    max-width: 100%;
}

.add-a-custom-field .custom-field-remove{
    width: 25px;
    height: 25px;
    border-radius: 3px;
    border: none;
    color: white;
    align-self: center;
    background: var(--primaryColor);
}

.add-a-custom-field .custom-field-remove span{
    pointer-events: none;
    width: 18px;
    height: 4px;
    border-radius: 4px;
    display: block;
    background: white;
}

.add-a-custom-field .add-custom-field-btn{
    background: var(--primaryColor);
    border: none;
    height: 28px;
    width: 28px;
    border-radius: 4px;
}

.add-a-custom-field .add-custom-field-btn *,
.add-a-custom-field .custom-field-remove *{
    pointer-events: none;
}

.add-a-custom-field .add-custom-field-btn svg path{
    stroke: white;
}

.product-add-submit-btn::after,
.product-add-submit-btn::before {
    background-color: var(--productBoxBg) !important;
}

.product-add-submit-btn {
    background: var(--primaryColor);
}

.show-loading-win {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background: #050505b3;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .2s ease-in-out;
}

.show-loading-win.active {
    opacity: 1;
    visibility: visible;
}

.inp-box .hidden-loc-map{
    height: 0px;
    overflow: hidden;
    transition: .3s ease-in-out;
}

/* ---------------- responsive ---------------- */
@media only screen and (max-width: 768px) {

    .inp-boxes-grid-container .input-split,
    .inp-boxes-grid-container .inp-four-grid {
        gap: 10px;
    }

    .inp-boxes-grid-container .address-box {
        flex-direction: column;
        gap: 0;
    }
}

@media only screen and (max-width: 550px) {

    .inp-boxes-grid-container .input-split,
    .inp-boxes-grid-container .inp-four-grid {
        flex-direction: column;
        gap: 0;
    }

    .inp-boxes-grid-container .input-split.price-inp,
    .inp-boxes-grid-container .address-box .input-split {
        flex-direction: row;
        gap: 10px;
    }

    .inp-boxes-grid-container .input-split .inp-box {
        width: 100% !important;
        max-width: 100% !important;
    }
}